var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "selectbox" }, [
    _c(
      "ul",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isOpen,
            expression: "isOpen",
          },
        ],
        staticClass: "list",
      },
      _vm._l(_vm.items, function (item) {
        return _c("select-box-item", {
          key: item,
          staticClass: "list-item",
          attrs: { item: item, label: item, icon: "none" },
          on: { select: _vm.onSelectItem },
        })
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }